// material-ui
import { Box, IconButton, Link, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { HomeOutlined } from "@ant-design/icons";

// project import
import Search from "./Search";
import Profile from "./Profile/index";
import MobileSection from "./MobileSection";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      <IconButton
        component={Link}
        href="https://firstcampus.net"
        target="_blank"
        disableRipple
        title="Aller sur le site vitrine"
        sx={{
          bgcolor: "white",
          color: theme.palette.text.secondary,
          fontSize: "20px",
        }}
      >
        <HomeOutlined />
      </IconButton>

      {/* <Notification /> */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
