import { compose, configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers/index";

const store = configureStore(
  {
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  },
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
);

const { dispatch } = store;

export { store, dispatch };
