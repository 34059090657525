// assets
import {
  HomeOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  HomeOutlined,
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const residences = {
  id: "permanent-residence",
  title: "Résidence permanente",
  type: "group",
  children: [
    {
      id: "quebec-visa-list",
      title: "Quebec",
      type: "item",
      url: "/permanent-residence-quebec-visa",
      icon: icons.HomeOutlined,
    },
    {
      id: "hors-quebec-visa-list",
      title: "Hors Quebec",
      type: "item",
      url: "/permanent-residence-hors-quebec-visa",
      icon: icons.HomeOutlined,
    },
  ],
};

export default residences;
