import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, IconButton, Toolbar, useMediaQuery } from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent/index";

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
	const theme = useTheme();
	const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

	const iconBackColor = "grey.100               ";
	const iconBackColorOpen = "grey.200";

	// common header
	const mainHeader = (
		<Toolbar>
			<IconButton
				disableRipple
				aria-label="open drawer"
				onClick={handleDrawerToggle}
				edge="start"
				color="secondary"
				sx={{
					color: "text.primary",
					"&:hover": {
						bgcolor: open ? iconBackColorOpen : iconBackColor,
					},
					ml: { xs: 0, lg: -2 },
				}}
			>
				{!open ? (
					<MenuUnfoldOutlined style={{ fontSize: "20px" }} />
				) : (
					<MenuFoldOutlined style={{ fontSize: "20px" }} />
				)}
			</IconButton>
			<HeaderContent />
		</Toolbar>
	);

	// app-bar params
	const appBar = {
		position: "fixed",
		color: "inherit",
		elevation: 0,
		sx: {
			borderBottom: `1px solid ${theme.palette.divider}`,
			backgroundColor: "#003f7d",
			color: "#fff"
		},
	};

	return (
		<>
			{!matchDownMD ? (
				<AppBarStyled open={open} {...appBar}>
					{mainHeader}
				</AppBarStyled>
			) : (
				<AppBar {...appBar}>{mainHeader}</AppBar>
			)}
		</>
	);
};

Header.propTypes = {
	open: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
};

export default Header;
