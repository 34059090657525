export const REGISTER_ERR_NETWORK = "ERR_NETWORK";

export const utilsMessage = (valeur) => {
  switch (valeur) {
    case REGISTER_ERR_NETWORK:
      return "Echec, Vous n'êtes pas connecté";
    default:
      break;
  }
};
