// assets
import { UnorderedListOutlined } from "@ant-design/icons";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";

// icons
const icons = { UnorderedListOutlined, AddCardOutlinedIcon };

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const Financial = {
	id: "group-financial",
	title: "Finances",
	type: "group",
	children: [
		{
			id: "customer-finance",
			title: "Finance Customer",
			type: "item",
			url: "/customer-finance",
			icon: icons.AddCardOutlinedIcon,
		},
		{
			id: "caq-finance",
			title: "Finance CAQ",
			type: "item",
			url: "/caq-finance",
			icon: icons.UnorderedListOutlined,
		},
	],
};

export default Financial;
