// project import
import account from "./utilisateurs";
import dashboard from "./dashboard";
import demands from "./demands";
import visa from "./visa";
import residences from "./residence";
import settings from "./settings";
import rSFDoc from "./visaAssist";
import financial from "./financial";
import business from "./business";
import { useSelector } from "react-redux";

// ==============================|| MENU ITEMS ||============================== //

function MenuItems() {
  const { user } = useSelector((state) => state.auth);
  if (user.isSuperAdmin)
    return {
      items: [
        demands,
        visa,
        residences,
        account,
        financial,
        business,
        settings,
      ],
    };
  else if (user.isAdmin)
    return {
      items: [demands, visa, residences, financial, business, settings],
    };
  else return { items: [demands, visa, residences, rSFDoc, settings] };
}

export default MenuItems;
