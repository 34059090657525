import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes/index";
import ThemeCustomization from "./themes";
import "./app.css";

const App = () => {
  return (
    <div>
      <ThemeCustomization>
        <ToastContainer />
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </ThemeCustomization>
    </div>
  );
};

export default App;
