import { combineReducers } from "redux";

import menu from "./menuReducer";
import auth from "./authReducer";
import demand from "./demandReducer";
import user from "./userReducer";
import business from "./businessReducer";
import finance from "./financeReducer";

const reducers = combineReducers({
  menu,
  auth,
  demand,
  user,
  finance,
  business,
});

export default reducers;
