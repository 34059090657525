import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack, Chip, Typography } from "@mui/material";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import { fontSize } from "@mui/system";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        {/* <img src="/static/logo/logo.png" height="90px" /> */}
        <Typography
          sx={{
            fontSize: "25px",
            fontWeight: 700,
            color: "white",
          }}
        >
          {process.env.REACT_APP_NAME}
        </Typography>
        <Chip
          label={process.env.REACT_APP_VERSION}
          size="small"
          sx={{
            backgroundColor: "white",
            height: 15,
            "& .MuiChip-label": { fontSize: "0.5rem", py: 0.25 },
          }}
        />
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
