// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  subscriptions: [],
  admins: [],
  clients: [],
  currentClient: {},
  userDetails: undefined,
  isAdminDataLoading: false,
  isClientDataLoading: false,
  isSubscriptionDataLoading: false,
  isUserDataLoading: false,
  isSubmitting: false,
  isEditing: false,
  isDeleting: false,
  isEditingStatus: false,
  userDetailsOpen: false,
};

// ==============================|| SLICE - AUTH ||============================== //

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    ADD_SUBSCRIPTIONS: (state, action) => {
      return { ...state, subscriptions: action.payload };
    },
    ADD_SUBSCRIPTION: (state, action) => {
      return {
        ...state,
        subscriptions: [action.payload, ...state.subscriptions],
      };
    },
    ADD_ADMIN: (state, action) => {
      return {
        ...state,
        admins: [action.payload, ...state.admins],
      };
    },
    ADD_CLIENT: (state, action) => {
      return {
        ...state,
        clients: [action.payload, ...state.clients],
      };
    },
    ADD_ADMINS: (state, action) => {
      return { ...state, admins: action.payload };
    },
    ADD_CLIENTS: (state, action) => {
      return { ...state, clients: action.payload };
    },
    UPDATE_ADMIN: (state, action) => {
      const indexToUpdate = state.admins.findIndex(
        (admin) => admin.user_id === action.payload.user_id
      );
      const updatedAdmins = [...state.admins];
      if (indexToUpdate !== -1) {
        updatedAdmins[indexToUpdate] = action.payload;
      } else {
        updatedAdmins.push(action.payload);
      }

      return { ...state, admins: updatedAdmins };
    },
    UPDATE_CLIENT: (state, action) => {
      const indexToUpdate = state.clients.findIndex(
        (client) => client.id === action.payload.id
      );
      const updatedClients = [...state.clients];
      if (indexToUpdate !== -1) {
        updatedClients[indexToUpdate] = action.payload;
      } else {
        updatedClients.push(action.payload);
      }

      return { ...state, clients: updatedClients };
    },
    UPDATE_SUBSCRIPTION: (state, action) => {
      const indexToUpdate = state.subscriptions.findIndex(
        (subscription) =>
          subscription.demandNumber === action.payload.demandNumber
      );
      const updatedSubscriptions = [...state.subscriptions];
      if (indexToUpdate !== -1) {
        updatedSubscriptions[indexToUpdate] = action.payload;
      } else {
        updatedSubscriptions.push(action.payload);
      }

      return { ...state, subscriptions: updatedSubscriptions };
    },
    DELETE_ADMIN: (state, action) => {
      const updatedAdmins = state.admins.filter(
        (admin) => admin.user_id !== action.payload.user_id
      );

      return { ...state, admins: updatedAdmins };
    },
    DELETE_CLIENT: (state, action) => {
      const updatedClients = state.clients.filter(
        (client) => client.id !== action.payload.id
      );

      return { ...state, admins: updatedClients };
    },
    ADD_USER_DETAILS: (state, action) => {
      return {
        ...state,
        userDetails: action.payload,
      };
    },
    ADD_CURRENT_CLIENT: (state, action) => {
      return { ...state, currentClient: action.payload };
    },
    SET_USER_DETAILS_OPEN: (state, action) => {
      return { ...state, userDetailsOpen: action.payload };
    },
    SET_IS_SUBMITTING: (state, action) => {
      return { ...state, isSubmitting: action.payload };
    },
    SET_IS_EDITING: (state, action) => {
      return { ...state, isEditing: action.payload };
    },
    SET_IS_DELETING: (state, action) => {
      return { ...state, isDeleting: action.payload };
    },
    SET_IS_EDITING_STATUS: (state, action) => {
      return { ...state, isEditingStatus: action.payload };
    },
    SET_IS_ADMIN_DATA_LOADING: (state, action) => {
      return { ...state, isAdminDataLoading: action.payload };
    },
    SET_IS_CLIENT_DATA_LOADING: (state, action) => {
      return { ...state, isClientDataLoading: action.payload };
    },
    SET_IS_SUBSCRIPTION_DATA_LOADING: (state, action) => {
      return { ...state, isSubscriptionDataLoading: action.payload };
    },
    SET_IS_USER_DATA_LOADING: (state, action) => {
      return { ...state, isUserDataLoading: action.payload };
    },
  },
});

export default user.reducer;
export const {
  ADD_SUBSCRIPTIONS,
  ADD_ADMINS,
  ADD_CLIENTS,
  ADD_ADMIN,
  ADD_CLIENT,
  UPDATE_ADMIN,
  UPDATE_CLIENT,
  DELETE_ADMIN,
  DELETE_CLIENT,
  ADD_SUBSCRIPTION,
  SET_IS_SUBMITTING,
  SET_IS_EDITING,
  SET_IS_DELETING,
  SET_IS_EDITING_STATUS,
  SET_IS_ADMIN_DATA_LOADING,
  SET_IS_CLIENT_DATA_LOADING,
  SET_IS_SUBSCRIPTION_DATA_LOADING,
  SET_IS_USER_DATA_LOADING,
  SET_USER_DETAILS_OPEN,
  ADD_USER_DETAILS,
  UPDATE_SUBSCRIPTION,
  ADD_CURRENT_CLIENT,
} = user.actions;
