import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { lazy } from "react";

import Loadable from "../components/loader/Loadable";
import MainLayout from "../layout/MainLayout";
import MinimalLayout from "../layout/MinimalLayout";

import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import UserRoutes from "./UserRoutes";
import AdminRoutes from "./AdminRoutes";
import ManagerRoutes from "./ManagerRoutes";

// Render
const UserDoc = Loadable(lazy(() => import("../pages/documentation")));

export default function ThemeRoutes() {
  const { user } = useSelector((state) => state.auth);

  let routes = [];
  let mainRoutesChildren = [];

  if (user.isSuperAdmin)
    mainRoutesChildren = [
      ...MainRoutes,
      ...ManagerRoutes,
      ...AdminRoutes,
      ...UserRoutes,
    ];
  else if (user.isAdmin)
    mainRoutesChildren = [...MainRoutes, ...ManagerRoutes, ...UserRoutes];
  else mainRoutesChildren = [...MainRoutes, ...UserRoutes];

  routes = [
    LoginRoutes,
    {
      path: "/",
      element: <MainLayout />,
      children: mainRoutesChildren,
    },
    {
      path: "/documentation",
      element: <MinimalLayout />,
      children: [
        {
          path: "user-manual",
          element: <UserDoc />,
        },
      ],
    },
  ];

  return useRoutes(routes);
}
