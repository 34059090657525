import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the main component
import { Viewer } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Worker
import { Worker } from "@react-pdf-viewer/core";

const PreviewDocs = ({
  isPreview,
  open,
  fileName,
  data,
  handleClose,
  handleAccept,
}) => {
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{fileName}</DialogTitle>
      <DialogContent>
        <div className="">
          {data && (
            <>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={data}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {!isPreview && <Button onClick={handleAccept}>Accepter</Button>}
        <Button onClick={handleClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDocs;
