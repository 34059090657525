import { lazy } from "react";
import Loadable from "../components/loader/Loadable";

// Render
const AdmissionFinance = Loadable(
  lazy(() => import("../pages/financials/AdmissionFinance"))
);

const CAQFinance = Loadable(
  lazy(() => import("../pages/financials/CAQFinance"))
);

const KYC = Loadable(lazy(() => import("../pages/users/KYC")));

const Consultation = Loadable(
  lazy(() => import("../pages/business/Consultation"))
);

const Validation = Loadable(lazy(() => import("../pages/business/Validation")));

const Input = Loadable(lazy(() => import("../pages/business/Input")));

// Routing
const ManagerRoutes = [
  {
    path: "customer-finance",
    element: <AdmissionFinance />,
  },
  {
    path: "caq-finance",
    element: <CAQFinance />,
  },
  {
    path: "kyc",
    element: <KYC />,
  },
  {
    path: "consultation-fees",
    element: <Consultation />,
  },
  {
    path: "financial-validation",
    element: <Validation />,
  },
  {
    path: "financial-input",
    element: <Input />,
  },
];

export default ManagerRoutes;
