import { notifyError } from "../../components/Toast/toast";
import {
  REGISTER_ERR_NETWORK,
  utilsMessage,
} from "../../hooks/actions/utilsMessage";
import { PRIMARY_COLOR } from "./Colors";

export const styleLink = {
  cursor: "pointer",
  textDecoration: "none",
  "&:hover": {
    color: PRIMARY_COLOR,
    textDecoration: "underline",
  },
  background: "none",
  border: "none",
  padding: "0px",
};

export const getErrorFetch = (error) => {
  if (error.code === REGISTER_ERR_NETWORK) {
    notifyError(utilsMessage(error.code));
  } else {
    notifyError(error.response.data.error_message);
  }
};
