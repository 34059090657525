import { notifyError } from "../../components/Toast/toast";
import { saveTokenInLocalStorage, login } from "../../services/authService";
import {
  FORBIDEN,
  LOGIN_FAILED_ACTION,
  SET_IS_SUBMITTING,
  LOGOUT,
} from "../reducers/authReducer";
import { REGISTER_ERR_NETWORK, utilsMessage } from "./utilsMessage";

export function logout(navigate) {
  return (dispatch) => {
    localStorage.removeItem("userDetails");

    dispatch({
      type: LOGOUT,
    });

    navigate("/security/login");
  };
}

export function loginAction(email, password, navigate, formik) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    login(email, password)
      .then((response) => {
        if (response.data.error_message !== undefined) {
          dispatch({
            type: LOGIN_FAILED_ACTION,
            payload: response.data.error_message,
          });
        } else {
          saveTokenInLocalStorage(response.data);
          navigate("/");
        }
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else if (error.response.status === 403) {
          dispatch({
            type: FORBIDEN,
          });
        }
      })
      .finally(() => {
        formik.resetForm();
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}
