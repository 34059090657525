// assets
import { SettingOutlined, SolutionOutlined } from "@ant-design/icons";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";

// icons
const icons = {
	SettingOutlined,
	SolutionOutlined,
	AddCardOutlinedIcon
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
	id: "group-settings",
	title: "Autres",
	type: "group",
	children: [
		// {
		//   id: "settings",
		//   title: "Paramètres",
		//   type: "item",
		//   url: "/settings",
		//   icon: icons.SettingOutlined,
		// },
		{
			id: "payment-finance",
			title: "Paiements",
			type: "item",
			url: "/payment",
			icon: icons.AddCardOutlinedIcon,
		},
		{
			id: "documentation",
			title: "Guide utilisateur",
			type: "item",
			url: "/documentation/user-manual",
			icon: icons.SolutionOutlined,
			target: true,
		},
	],
};

export default support;
