// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  consultations: [],
  validations: [],
  inputs: [],
  payments: [],
  currentConsultation: {},
  currentValidation: {},
  currentInput: {},
  currentPayment: {},
  isConsultationDataLoading: false,
  isValidationDataLoading: false,
  isInputDataLoading: false,
  isPaymentDataLoading: false,
  isSubmitting: false,
  isEditing: false,
  isDeleting: false,
  isEditingStatus: false,
  DocState: {
    file: {
      data: undefined,
      dataToSave: undefined,
      name: undefined,
      id: undefined,
    },
    fileSelected: undefined,
    openConfirm: {
      openSubmitConfirm: false,
      openProcessingConfirm: false,
      openValidateDocumentConfirm: false,
      openRejectDocumentConfirm: false,
      openCloseDemand: false,
    },
    isPreview: false,
    openDoc: false,
  },
  isSavingDoc: false,
};

// ==============================|| SLICE - AUTH ||============================== //

const business = createSlice({
  name: "business",
  initialState,
  reducers: {
    ADD_CONSULTATIONS: (state, action) => {
      return { ...state, consultations: action.payload };
    },
    ADD_CONSULTATION: (state, action) => {
      return {
        ...state,
        consultations: [action.payload, ...state.consultations],
      };
    },
    UPDATE_CONSULTATION: (state, action) => {
      const indexToUpdate = state.consultations.findIndex(
        (consultation) => consultation.code === action.payload.code
      );
      const updatedConsultations = [...state.consultations];
      if (indexToUpdate !== -1) {
        updatedConsultations[indexToUpdate] = action.payload;
      } else {
        updatedConsultations.push(action.payload);
      }
      return { ...state, consultations: updatedConsultations };
    },
    DELETE_CONSULTATION: (state, action) => {
      const updatedConsultations = state.consultations.filter(
        (consultation) => consultation.id !== action.payload
      );

      return { ...state, consultations: updatedConsultations };
    },
    ADD_CURRENT_CONSULTATION: (state, action) => {
      return { ...state, currentConsultation: action.payload };
    },
    ADD_VALIDATIONS: (state, action) => {
      return { ...state, validations: action.payload };
    },
    ADD_VALIDATION: (state, action) => {
      return {
        ...state,
        validations: [action.payload, ...state.validations],
      };
    },
    UPDATE_VALIDATION: (state, action) => {
      const indexToUpdate = state.validations.findIndex(
        (validation) => validation.id === action.payload.id
      );
      const updatedValidations = [...state.validations];
      if (indexToUpdate !== -1) {
        updatedValidations[indexToUpdate] = action.payload;
      } else {
        updatedValidations.push(action.payload);
      }
      return { ...state, validations: updatedValidations };
    },
    DELETE_VALIDATION: (state, action) => {
      const updatedValidations = state.validations.filter(
        (validation) => validation.id !== action.payload
      );

      return { ...state, validations: updatedValidations };
    },
    ADD_CURRENT_VALIDATION: (state, action) => {
      return { ...state, currentValidation: action.payload };
    },
    ADD_INPUTS: (state, action) => {
      return { ...state, inputs: action.payload };
    },
    ADD_INPUT: (state, action) => {
      return {
        ...state,
        inputs: [action.payload, ...state.inputs],
      };
    },
    UPDATE_INPUT: (state, action) => {
      const indexToUpdate = state.inputs.findIndex(
        (input) => input.id === action.payload.id
      );
      const updatedInputs = [...state.inputs];
      if (indexToUpdate !== -1) {
        updatedInputs[indexToUpdate] = action.payload;
      } else {
        updatedInputs.push(action.payload);
      }
      return { ...state, inputs: updatedInputs };
    },
    DELETE_INPUT: (state, action) => {
      const updatedInputs = state.inputs.filter(
        (input) => input.id !== action.payload
      );

      return { ...state, inputs: updatedInputs };
    },
    ADD_CURRENT_INPUT: (state, action) => {
      return { ...state, currentInput: action.payload };
    },
    ADD_PAYMENTS: (state, action) => {
      return { ...state, payments: action.payload };
    },
    ADD_PAYMENT: (state, action) => {
      return {
        ...state,
        payments: [action.payload, ...state.payments],
      };
    },
    UPDATE_PAYMENT: (state, action) => {
      const indexToUpdate = state.payments.findIndex(
        (payment) => payment.code === action.payload.code
      );
      const updatedPayments = [...state.payments];
      if (indexToUpdate !== -1) {
        updatedPayments[indexToUpdate] = action.payload;
      } else {
        updatedPayments.push(action.payload);
      }
      return { ...state, payments: updatedPayments };
    },
    DELETE_PAYMENT: (state, action) => {
      const updatedPayments = state.payments.filter(
        (payment) => payment.id !== action.payload
      );
      return { ...state, payments: updatedPayments };
    },
    ADD_CURRENT_PAYMENT: (state, action) => {
      return { ...state, currentPayment: action.payload };
    },
    SET_IS_SUBMITTING: (state, action) => {
      return { ...state, isSubmitting: action.payload };
    },
    SET_IS_EDITING: (state, action) => {
      return { ...state, isEditing: action.payload };
    },
    SET_IS_DELETING: (state, action) => {
      return { ...state, isDeleting: action.payload };
    },
    SET_IS_EDITING_STATUS: (state, action) => {
      return { ...state, isEditingStatus: action.payload };
    },
    SET_IS_CONSULTATION_DATA_LOADING: (state, action) => {
      return { ...state, isConsultationDataLoading: action.payload };
    },
    SET_IS_VALIDATION_DATA_LOADING: (state, action) => {
      return { ...state, isValidationDataLoading: action.payload };
    },
    SET_IS_INPUT_DATA_LOADING: (state, action) => {
      return { ...state, isInputDataLoading: action.payload };
    },
    SET_IS_PAYMENT_DATA_LOADING: (state, action) => {
      return { ...state, isPaymentDataLoading: action.payload };
    },
    SET_IS_SAVING_DOC: (state, action) => {
      return { ...state, isSavingDoc: action.payload };
    },

    SET_OPEN_CONFIRM: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, openConfirm: action.payload },
      };
    },

    SET_FILE_SELECTED: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, fileSelected: action.payload },
      };
    },

    SET_FILE: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, file: action.payload },
      };
    },

    SET_OPEN_DOC: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, openDoc: action.payload },
      };
    },

    SET_IS_PREVIEW: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, isPreview: action.payload },
      };
    },
  },
});

export default business.reducer;
export const {
  ADD_CONSULTATIONS,
  ADD_CONSULTATION,
  UPDATE_CONSULTATION,
  DELETE_CONSULTATION,
  ADD_CURRENT_CONSULTATION,
  ADD_VALIDATIONS,
  ADD_VALIDATION,
  UPDATE_VALIDATION,
  DELETE_VALIDATION,
  ADD_CURRENT_VALIDATION,
  ADD_PAYMENTS,
  ADD_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
  ADD_CURRENT_PAYMENT,
  ADD_INPUTS,
  ADD_INPUT,
  UPDATE_INPUT,
  DELETE_INPUT,
  ADD_CURRENT_INPUT,
  SET_IS_SUBMITTING,
  SET_IS_EDITING,
  SET_IS_DELETING,
  SET_IS_EDITING_STATUS,
  SET_IS_CONSULTATION_DATA_LOADING,
  SET_IS_VALIDATION_DATA_LOADING,
  SET_IS_INPUT_DATA_LOADING,
  SET_IS_PAYMENT_DATA_LOADING,
  SET_OPEN_CONFIRM,
  SET_FILE_SELECTED,
  SET_FILE,
  SET_OPEN_DOC,
  SET_IS_PREVIEW,
  SET_IS_SAVING_DOC,
} = business.actions;
