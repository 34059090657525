// types
import { createSlice } from "@reduxjs/toolkit";

export const LOGIN = "@auth/security/login";

// initial state
const initialState = {
  rsfData: {},
  demands: [],
  activeDemand: {
    demandNumber: undefined,
    isSended: true,
    isProcessing: true,
    isFinnished: false,
    canBeModified: true,
    docs: [],
  },
  DocState: {
    file: {
      data: undefined,
      dataToSave: undefined,
      name: undefined,
      id: undefined,
    },
    fileSelected: undefined,
    openConfirm: {
      openSubmitConfirm: false,
      openProcessingConfirm: false,
      openValidateDocumentConfirm: false,
      openRejectDocumentConfirm: false,
      openCloseDemand: false,
    },
    isPreview: false,
    openDoc: false,
  },
  document: {},
  demanNumber: undefined,
  isDataLoading: false,
  isSubmitting: false,
  isOperatorChange: false,
  isSavingDoc: false,
  isValidating: false,
  isRejecting: false,
  isDeleting: false,
  isError: false,
  errorMessage: undefined,
  status: "En cours de traitement",
};

// ==============================|| SLICE - AUTH ||============================== //

const demand = createSlice({
  name: "demand",
  initialState,
  reducers: {
    ADD_DEMANDS: (state, action) => {
      return {
        ...state,
        demands: action.payload,
        status: action.payload.status,
      };
    },

    CHANGE_STATUS_DEMAND: (state, action) => {
      return { ...state, status: action.payload.status };
    },

    RESET_DEMANDS: (state, action) => {
      return {
        ...state,
        demands: action.payload,
        status: action.payload.status,
      };
    },

    ADD_DEMAND: (state, action) => {
      return {
        ...state,
        activeDemand: action.payload,
        demanNumber: action.payload.demandNumber,
        rsfData: { ...state.rsfData, rsfDoc: action.payload.rsfDoc },
        isError: false,
        errorMessage: undefined,
        status: action.payload.status,
      };
    },

    ADD_RSF_DATA: (state, action) => {
      return {
        ...state,
        rsfData: action.payload,
        demanNumber: action.payload.demandNumber,
      };
    },

    SET_OPEN_CONFIRM: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, openConfirm: action.payload },
      };
    },

    SET_FILE_SELECTED: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, fileSelected: action.payload },
      };
    },

    SET_FILE: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, file: action.payload },
      };
    },

    SET_OPEN_DOC: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, openDoc: action.payload },
      };
    },

    SET_IS_PREVIEW: (state, action) => {
      return {
        ...state,
        DocState: { ...state.DocState, isPreview: action.payload },
      };
    },

    SET_IS_DATA_LOADING: (state, action) => {
      return { ...state, isDataLoading: action.payload };
    },

    SET_IS_SAVING_DOC: (state, action) => {
      return { ...state, isSavingDoc: action.payload };
    },

    SET_IS_SUBMITTING: (state, action) => {
      return { ...state, isSubmitting: action.payload };
    },

    SET_IS_OPERATOR_CHANGE: (state, action) => {
      return { ...state, isOperatorChange: action.payload };
    },

    SET_IS_VALIDATING: (state, action) => {
      return { ...state, isValidating: action.payload };
    },

    SET_IS_REJECTING: (state, action) => {
      return { ...state, isRejecting: action.payload };
    },

    SET_IS_DELETING: (state, action) => {
      return { ...state, isDeleting: action.payload };
    },

    SET_ERROR: (state, action) => {
      return { ...state, isError: true, errorMessage: action.payload };
    },
  },
});

export default demand.reducer;
export const {
  ADD_DEMANDS,
  ADD_DEMAND,
  ADD_RSF_DATA,
  SET_OPEN_CONFIRM,
  SET_FILE_SELECTED,
  SET_FILE,
  SET_OPEN_DOC,
  SET_IS_PREVIEW,
  SET_IS_DATA_LOADING,
  SET_IS_SAVING_DOC,
  SET_IS_SUBMITTING,
  SET_IS_OPERATOR_CHANGE,
  SET_IS_VALIDATING,
  SET_IS_REJECTING,
  SET_IS_DELETING,
  SET_ERROR,
  CHANGE_STATUS_DEMAND,
} = demand.actions;
