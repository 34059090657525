import axios from "axios";

export const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const fetch = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const fetchWithAuthorizationAndFile = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});
