// assets
import { UserSwitchOutlined, TeamOutlined, VerifiedOutlined } from "@ant-design/icons";

// icons
const icons = {
	UserSwitchOutlined,
	TeamOutlined,
	VerifiedOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
	id: "group-users",
	title: "Utilisateurs",
	type: "group",
	children: [
		{
			id: "admins",
			title: "Collaborateurs",
			type: "item",
			url: "/admins",
			icon: icons.UserSwitchOutlined,
		},
		{
			id: "subscribers",
			title: "Clients",
			type: "item",
			url: "/subscribers",
			icon: icons.TeamOutlined,
		},
		{
			id: "kyc",
			title: "KYC",
			type: "item",
			url: "/kyc",
			icon: icons.VerifiedOutlined,
		},
	],
};

export default pages;
