// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
	admissionsFinance: [],
	currentDemand: {},
	processingDemands: [],
	isDataLoading: false,
	isSubmitting: false,
};

// ==============================|| SLICE - AUTH ||============================== //

const finance = createSlice({
	name: "finance",
	initialState,
	reducers: {
		ADD_ADMISSIONS_FINANCE: (state, action) => {
			return { ...state, admissionsFinance: action.payload };
		},
		ADD_ADMISSION_FINANCE: (state, action) => {
			return {
				...state,
				admissionsFinance: [action.payload, ...state.admissionsFinance],
			};
		},
		EDIT_ADMISSION_FINANCE: (state, action) => {
			var data = state.admissionsFinance.filter(
				(demand) => demand.id !== action.payload.id
			);
			return {
				...state,
				admissionsFinance: [action.payload, ...data],
			};
		},
		ADD_CURRENT_DEMAND: (state, action) => {
			return { ...state, currentDemand: action.payload };
		},
		ADD_PROCESSING_DEMANDS: (state, action) => {
			return { ...state, processingDemands: action.payload };
		},
		SET_IS_DATA_LOADING: (state, action) => {
			return { ...state, isDataLoading: action.payload };
		},
		SET_IS_SUBMITTING: (state, action) => {
			return { ...state, isSubmitting: action.payload };
		},
	},
});

export default finance.reducer;
export const {
	ADD_ADMISSIONS_FINANCE,
	ADD_ADMISSION_FINANCE,
	EDIT_ADMISSION_FINANCE,
	ADD_CURRENT_DEMAND,
	SET_IS_DATA_LOADING,
	ADD_PROCESSING_DEMANDS,
	SET_IS_SUBMITTING,
} = finance.actions;
