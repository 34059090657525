import { lazy } from "react";
import Loadable from "../components/loader/Loadable";

// Render
const Admins = Loadable(lazy(() => import("../pages/users/Admins")));

const Abonnements = Loadable(lazy(() => import("../pages/users/Abonnements")));

// Routing
const AdminRoutes = [
  {
    path: "admins",
    element: <Admins />,
  },
  {
    path: "subscribers",
    element: <Abonnements />,
  },
];

export default AdminRoutes;
