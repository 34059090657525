// assets
import {
  BookOutlined,
  UserOutlined,
  IdcardOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  BookOutlined,
  UserOutlined,
  IdcardOutlined,
};

// ==============================|| MENU ITEMS - VISA ||============================== //

const visas = {
  id: "group-visa",
  title: "Visa",
  type: "group",
  children: [
    {
      id: "visitor-visa-list",
      title: "Visa visiteur",
      type: "item",
      url: "/visitor-visa",
      icon: icons.UserOutlined,
    },
    {
      id: "work-visa-list",
      title: "Visa travail",
      type: "item",
      url: "/work-visa",
      icon: icons.IdcardOutlined,
    },
    {
      id: "study-visa-list",
      title: "Visa étude",
      type: "item",
      url: "/study-visa",
      icon: icons.BookOutlined,
    },
  ],
};

export default visas;
