import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authError: false,
  errMessage: "",
  user: {
    username: undefined,
    isStudent: undefined,
    isAdmin: undefined,
    isSuperAdmin: undefined,
    roles: undefined,
    token: undefined,
  },
  isSubmitting: false,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGIN_CONFIRMED_ACTION: (state, { payload }) => {
      if (payload.token.roles.indexOf("super-admin") !== -1)
        return {
          ...state,
          user: {
            username: payload.token.sub,
            isStudent: false,
            isAdmin: false,
            isSuperAdmin: true,
            roles: payload.token.roles,
            token: payload.access_token,
          },
        };
      else if (
        payload.token.roles.indexOf("admin") !== -1 ||
        payload.token.roles.indexOf("manager") !== -1
      )
        return {
          ...state,
          user: {
            username: payload.token.sub,
            isStudent: false,
            isAdmin: true,
            isSuperAdmin: false,
            roles: payload.token.roles,
            token: payload.access_token,
          },
        };
      else if (payload.token.roles.indexOf("student") !== -1) {
        console.log(payload.access_token);
        return {
          ...state,
          user: {
            username: payload.token.sub,
            isStudent: true,
            isAdmin: false,
            isSuperAdmin: false,
            roles: payload.token.roles,
            token: payload.access_token,
          },
        };
      }
        
    },

    LOGIN_FAILED_ACTION: (state, { payload }) => {
      return {
        ...state,
        authError: true,
        errMessage: payload,
      };
    },

    FORBIDEN: (state, { payload }) => {
      return {
        ...state,
        authError: true,
        errMessage:
          "Echec de la connexion. Vous ne pouvez plus vous connecter car ce compte a été temporairement ou permanement bloqué." +
          payload,
      };
    },

    SET_IS_SUBMITTING: (state, action) => {
      return { ...state, isSubmitting: action.payload };
    },

    LOGOUT: (state) => {
      return {
        ...state,
        authError: false,
        errMessage: "",
        user: {
          username: undefined,
          isStudent: undefined,
          isAdmin: undefined,
          isSuperAdmin: undefined,
          roles: undefined,
          token: undefined,
        },
        isSubmitting: false,
      };
    },
  },
});

export default auth.reducer;

export const {
  LOGIN_FAILED_ACTION,
  LOGIN_CONFIRMED_ACTION,
  SET_IS_SUBMITTING,
  FORBIDEN,
  LOGOUT,
} = auth.actions;
