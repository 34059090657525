import { LOGIN_CONFIRMED_ACTION, LOGOUT } from "../hooks/reducers/authReducer";
import { fetch } from "./api";
import jwt from "jwt-decode";

export function login(email, password) {
  return fetch.get(`/auth/login`, {
    params: { username: email, password: password },
  });
}

export async function refreshToken(token) {
  return await fetch.get(`/auth/token/refresh`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function checkAutoLogin(dispatch, navigate) {
  let todaysDate = new Date().getTime();
  const userDetails = localStorage.getItem("userDetails");

  const tokenDetails = JSON.parse(userDetails);

  if (!tokenDetails) {
    localStorage.removeItem("userDetails");

    dispatch({
      type: LOGOUT,
    });

    navigate("/security/login");
  } else if (todaysDate > tokenDetails.exp) {
    localStorage.removeItem("userDetails");

    dispatch({
      type: LOGOUT,
    });

    navigate("/security/login");
  } else {
    try {
      const token = jwt(tokenDetails.access_token);

      dispatch({
        type: LOGIN_CONFIRMED_ACTION,
        payload: { token: token, access_token: tokenDetails.access_token },
      });
    } catch (error) {
      localStorage.removeItem("userDetails");

      dispatch({
        type: LOGOUT,
      });

      navigate("/security/login");
    }
  }
}
