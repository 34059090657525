import { notifyError, notifySuccess } from "../../components/Toast/toast";
import { getErrorFetch } from "../../pages/shared/globalElement";
import {
  createConsultation,
  createInput,
  createPayment,
  createValidation,
  deleteConsultation,
  deleteInput,
  deletePayment,
  deleteValidation,
  editDocument,
  editPaymentDocument,
  getAllConsultation,
  getAllInput,
  getAllPayment,
  getAllPaymentByCustomer,
  getAllValidation,
  getTotalPayment,
  toggleValidConsultation,
  toggleValidInput,
  toggleValidPayment,
  toggleValidValidation,
  uploadInputProof,
  uploadPaymentProof,
  uploadProof,
} from "../../services/businessService";
import {
  ADD_CONSULTATIONS,
  ADD_CONSULTATION,
  UPDATE_CONSULTATION,
  DELETE_CONSULTATION,
  ADD_CURRENT_CONSULTATION,
  ADD_VALIDATIONS,
  ADD_VALIDATION,
  UPDATE_VALIDATION,
  DELETE_VALIDATION,
  ADD_CURRENT_VALIDATION,
  ADD_INPUTS,
  ADD_INPUT,
  UPDATE_INPUT,
  DELETE_INPUT,
  ADD_CURRENT_INPUT,
  SET_IS_SUBMITTING,
  SET_IS_EDITING,
  SET_IS_DELETING,
  SET_IS_EDITING_STATUS,
  SET_IS_CONSULTATION_DATA_LOADING,
  SET_IS_VALIDATION_DATA_LOADING,
  SET_IS_INPUT_DATA_LOADING,
  SET_OPEN_CONFIRM,
  SET_FILE_SELECTED,
  SET_FILE,
  SET_OPEN_DOC,
  SET_IS_PREVIEW,
  SET_IS_SAVING_DOC,
  ADD_PAYMENTS,
  ADD_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
  ADD_CURRENT_PAYMENT,
  SET_IS_PAYMENT_DATA_LOADING,
} from "../reducers/businessReducer";
import { REGISTER_ERR_NETWORK, utilsMessage } from "./utilsMessage";

export function EditOpenConfirm(data) {
  return (dispatch) => {
    dispatch({
      type: SET_OPEN_CONFIRM,
      payload: data,
    });
  };
}

export function SetFileSelected(data) {
  return (dispatch) => {
    dispatch({
      type: SET_FILE_SELECTED,
      payload: data,
    });
  };
}

export function SetFile(data) {
  return (dispatch) => {
    dispatch({
      type: SET_FILE,
      payload: data,
    });
  };
}

export function SetOpen(data) {
  return (dispatch) => {
    dispatch({
      type: SET_OPEN_DOC,
      payload: data,
    });
  };
}

export function SetIsPreview(data) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_PREVIEW,
      payload: data,
    });
  };
}

export function processGetAllConsultations(token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_CONSULTATION_DATA_LOADING,
      payload: true,
    });
    getAllConsultation(token)
      .then((response) => {
        dispatch({
          type: ADD_CONSULTATIONS,
          payload: response.data.consultations,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_CONSULTATION_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processCreateConsultation(values, editing, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });
    createConsultation(values, token)
      .then((response) => {
        if (editing) {
          dispatch({
            type: UPDATE_CONSULTATION,
            payload: response.data.consultation,
          });
          notifySuccess("Modifiée !");
        } else {
          dispatch({
            type: ADD_CONSULTATION,
            payload: response.data.consultation,
          });
          notifySuccess("Ajoutée !");
        }
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processToggleValidConsultation(id, status, operator, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDITING_STATUS,
      payload: true,
    });
    toggleValidConsultation(id, status, operator, token)
      .then((response) => {
        dispatch({
          type: UPDATE_CONSULTATION,
          payload: response.data.consultation,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_EDITING_STATUS,
          payload: false,
        });
      });
  };
}

export function processDeleteConsultation(id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DELETING,
      payload: true,
    });
    deleteConsultation(id, token)
      .then((response) => {
        dispatch({
          type: DELETE_CONSULTATION,
          payload: id,
        });
        notifySuccess("Supprimé !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DELETING,
          payload: false,
        });
      });
  };
}

export function AddCurrentConsultation(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_CURRENT_CONSULTATION,
      payload: data,
    });
  };
}

export function storeProof(file, document_id, consultation_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SAVING_DOC,
      payload: true,
    });

    editDocument(file, document_id, consultation_id, token)
      .then((response) => {
        dispatch({
          type: UPDATE_CONSULTATION,
          payload: response.data.consultation,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SAVING_DOC,
          payload: false,
        });
      });
  };
}

export function processStoreProof(file, consultation_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    uploadProof(file, consultation_id, token)
      .then((response) => {
        dispatch({
          type: UPDATE_CONSULTATION,
          payload: response.data.consultation,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error.response.data.error_message);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processCreateValidation(values, editing, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });
    createValidation(values, token)
      .then((response) => {
        if (editing) {
          dispatch({
            type: UPDATE_VALIDATION,
            payload: response.data.validation,
          });
          notifySuccess("Modifiée !");
        } else {
          dispatch({
            type: ADD_VALIDATION,
            payload: response.data.validation,
          });
          notifySuccess("Ajoutée !");
        }
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processGetAllValidations(token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_VALIDATION_DATA_LOADING,
      payload: true,
    });
    getAllValidation(token)
      .then((response) => {
        dispatch({
          type: ADD_VALIDATIONS,
          payload: response.data.validations,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_VALIDATION_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function AddCurrentValidation(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_CURRENT_VALIDATION,
      payload: data,
    });
  };
}

export function processToggleValidValidation(id, status, operator, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDITING_STATUS,
      payload: true,
    });
    toggleValidValidation(id, status, operator, token)
      .then((response) => {
        dispatch({
          type: UPDATE_VALIDATION,
          payload: response.data.validation,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_EDITING_STATUS,
          payload: false,
        });
      });
  };
}

export function processDeleteValidation(id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DELETING,
      payload: true,
    });
    deleteValidation(id, token)
      .then((response) => {
        dispatch({
          type: DELETE_VALIDATION,
          payload: id,
        });
        notifySuccess("Supprimé !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DELETING,
          payload: false,
        });
      });
  };
}

export function processCreateInput(values, editing, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });
    createInput(values, token)
      .then((response) => {
        if (editing) {
          dispatch({
            type: UPDATE_INPUT,
            payload: response.data.input,
          });
          notifySuccess("Modifiée !");
        } else {
          dispatch({
            type: ADD_INPUT,
            payload: response.data.input,
          });
          notifySuccess("Ajoutée !");
        }
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processGetAllInputs(token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_INPUT_DATA_LOADING,
      payload: true,
    });
    getAllInput(token)
      .then((response) => {
        dispatch({
          type: ADD_INPUTS,
          payload: response.data.inputs,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_INPUT_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function AddCurrentInput(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_CURRENT_INPUT,
      payload: data,
    });
  };
}

export function processToggleValidInput(id, status, operator, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDITING_STATUS,
      payload: true,
    });
    toggleValidInput(id, status, operator, token)
      .then((response) => {
        dispatch({
          type: UPDATE_INPUT,
          payload: response.data.input,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_EDITING_STATUS,
          payload: false,
        });
      });
  };
}

export function processDeleteInput(id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DELETING,
      payload: true,
    });
    deleteInput(id, token)
      .then((response) => {
        dispatch({
          type: DELETE_INPUT,
          payload: id,
        });
        notifySuccess("Supprimé !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DELETING,
          payload: false,
        });
      });
  };
}

export function processStoreInputProof(file, input_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    uploadInputProof(file, input_id, token)
      .then((response) => {
        dispatch({
          type: UPDATE_INPUT,
          payload: response.data.input,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error.response.data.error_message);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processGetAllPayments(token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_PAYMENT_DATA_LOADING,
      payload: true,
    });
    getAllInput(token)
      .then((response) => {
        dispatch({
          type: ADD_PAYMENTS,
          payload: response.data.inputs,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_PAYMENT_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processGetAllPaymentsByCustomer(customer, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_PAYMENT_DATA_LOADING,
      payload: true,
    });
    getAllPaymentByCustomer(customer, token)
      .then((response) => {
        dispatch({
          type: ADD_PAYMENTS,
          payload: response.data.inputs,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_PAYMENT_DATA_LOADING,
          payload: false,
        });
      });
  };
}

export function processCreatePayment(values, editing, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });
    createPayment(values, token)
      .then((response) => {
        if (editing) {
          dispatch({
            type: UPDATE_PAYMENT,
            payload: response.data.payment,
          });
          notifySuccess("Modifiée !");
        } else {
          dispatch({
            type: ADD_PAYMENT,
            payload: response.data.payment,
          });
          notifySuccess("Ajoutée !");
        }
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

export function processToggleValidPayment(id, status, operator, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_EDITING_STATUS,
      payload: true,
    });
    toggleValidPayment(id, status, operator, token)
      .then((response) => {
        dispatch({
          type: UPDATE_PAYMENT,
          payload: response.data.payment,
        });
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_EDITING_STATUS,
          payload: false,
        });
      });
  };
}

export function processDeletePayment(id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_DELETING,
      payload: true,
    });
    deletePayment(id, token)
      .then((response) => {
        dispatch({
          type: DELETE_PAYMENT,
          payload: id,
        });
        notifySuccess("Supprimé !");
      })
      .catch((error) => {
        if (error.code === REGISTER_ERR_NETWORK) {
          notifyError(utilsMessage(error.code));
        } else {
          notifyError(error.response.data.error_message);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_IS_DELETING,
          payload: false,
        });
      });
  };
}

export function AddCurrentPayment(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_CURRENT_PAYMENT,
      payload: data,
    });
  };
}

export function storePaymentProof(file, document_id, payment_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SAVING_DOC,
      payload: true,
    });

    editPaymentDocument(file, document_id, payment_id, token)
      .then((response) => {
        dispatch({
          type: UPDATE_PAYMENT,
          payload: response.data.payment,
        });
      })
      .catch((error) => {
        getErrorFetch(error);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SAVING_DOC,
          payload: false,
        });
      });
  };
}

export function processStorePaymentProof(file, payment_id, token) {
  return (dispatch) => {
    dispatch({
      type: SET_IS_SUBMITTING,
      payload: true,
    });

    uploadPaymentProof(file, payment_id, token)
      .then((response) => {
        dispatch({
          type: UPDATE_PAYMENT,
          payload: response.data.payment,
        });
        notifySuccess("Success");
      })
      .catch((error) => {
        getErrorFetch(error.response.data.error_message);
      })
      .finally(() => {
        dispatch({
          type: SET_IS_SUBMITTING,
          payload: false,
        });
      });
  };
}

