import { fetch, fetchWithAuthorizationAndFile } from "./api";

export const createConsultation = async (value, token) => {
  return await fetch.post(
    "/business/consultation/add",
    {
      name: value.name,
      firstname: value.firstname,
      email: value.email,
      phone: value.phone,
      reason: value.reason,
      amount: value.amount,
      code: value.code,
      id: value.id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAllConsultation = async (token) => {
  return await fetch.get("/business/consultations", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteConsultation = async (id, token) => {
  return await fetch.get("/business/consultation/delete", {
    params: { consultation_id: id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const toggleValidConsultation = async (id, status, operator, token) => {
  return await fetch.get("/business/consultation/valid", {
    params: { id: id, status: status, operator: operator },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadProof = async (file, consultation_id, token) => {
  let formData = new FormData();

  formData.append("file", file);

  formData.append("consultation_id", consultation_id);

  return await fetchWithAuthorizationAndFile.post(
    `/business/consultation/upload/proof`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const uploadInputProof = async (file, input_id, token) => {
  let formData = new FormData();

  formData.append("file", file);

  formData.append("input_id", input_id);

  return await fetchWithAuthorizationAndFile.post(
    `/business/input/upload/proof`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const uploadPaymentProof = async (file, payment_id, token) => {
  let formData = new FormData();

  formData.append("file", file);

  formData.append("payment_id", payment_id);

  return await fetchWithAuthorizationAndFile.post(
    `/payment/upload/proof`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const editPaymentDocument = async (
  file,
  document_id,
  payment_id,
  token
) => {
  const form = new FormData();

  form.append("file", file);

  form.append("document_id", document_id);

  form.append("payment_id", payment_id);

  return await fetchWithAuthorizationAndFile.post("/payment/upload", form, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const editDocument = async (
  file,
  document_id,
  consultation_id,
  token
) => {
  const form = new FormData();

  form.append("file", file);

  form.append("document_id", document_id);

  form.append("consultation_id", consultation_id);

  return await fetchWithAuthorizationAndFile.post(
    "/business/consultation/upload",
    form,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createValidation = async (value, token) => {
  return await fetch.post(
    "/business/validation/add",
    {
      type: value.type,
      name: value.name,
      description: value.description,
      amount: value.amount,
      level: value.level,
      delayDate: value.delayDate,
      id: value.id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAllValidation = async (token) => {
  return await fetch.get("/business/validations", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteValidation = async (id, token) => {
  return await fetch.get("/business/validation/delete", {
    params: { validation_id: id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const toggleValidValidation = async (id, status, operator, token) => {
  return await fetch.get("/business/validation/valid", {
    params: { id: id, status: status, operator: operator },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createInput = async (value, token) => {
  return await fetch.post(
    "/business/input/add",
    {
      type: value.type,
      name: value.name,
      amount: value.amount,
      demand: value.demand,
      id: value.id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAllInput = async (token) => {
  return await fetch.get("/business/inputs", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteInput = async (id, token) => {
  return await fetch.get("/business/input/delete", {
    params: { input_id: id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const toggleValidInput = async (id, status, operator, token) => {
  return await fetch.get("/business/input/valid", {
    params: { id: id, status: status, operator: operator },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createPayment = async (value, token) => {
  return await fetch.post(
    "/payment/add",
    {
      description: value.description,
      amount: value.amount,
      code: value.code,
      demand: value.demand,
      id: value.id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAllPayment = async (token) => {
  return await fetch.get("/payment/all", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllPaymentByCustomer = async (customer, token) => {
  return await fetch.get("/business/input/all/by-customer", {
    params: { customer: customer },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deletePayment = async (id, token) => {
  return await fetch.get("/payment/delete", {
    params: { payment_id: id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const toggleValidPayment = async (id, status, operator, token) => {
  return await fetch.get("/payment/valid", {
    params: { id: id, status: status, operator: operator },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
