import { lazy } from "react";
import Loadable from "../components/loader/Loadable";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";

// Render
const Admission = Loadable(lazy(() => import("../pages/demands/Admission")));

const VisaVisiteur = Loadable(
  lazy(() => import("../pages/demands/VisaVisiteur"))
);

const VisaEtude = Loadable(lazy(() => import("../pages/demands/VisaEtude")));

const VisaTravail = Loadable(
  lazy(() => import("../pages/demands/VisaTravail"))
);

const ResidencePermanenteQuebec = Loadable(
  lazy(() => import("../pages/demands/ResidencePermanenteQuebec"))
);

const ResidencePermanenteHorsQuebec = Loadable(
  lazy(() => import("../pages/demands/ResidencePermanenteHorsQuebec"))
);

const Logement = Loadable(lazy(() => import("../pages/demands/Logement")));

const Accueil = Loadable(lazy(() => import("../pages/demands/Accueil")));

const DemandDetails = Loadable(
  lazy(() => import("../pages/demands/DemandDetail"))
);

const NotFound = Loadable(lazy(() => import("../pages/ErrorPage/NotFound")));

const CAQ = Loadable(lazy(() => import("../pages/demands/CAQ")));

// Routing
const MainRoutes = [
  {
    path: "/",
    element: <Navigate to="admissions" />,
  },
  {
    path: "dashboard",
    element: <Box>Dashboard En cours de développement</Box>,
  },
  {
    path: "admissions",
    element: <Admission />,
  },
  {
    path: "demand/:_number",
    element: <DemandDetails />,
  },
  {
    path: "housings",
    element: <Logement />,
  },
  {
    path: "visitor-visa",
    element: <VisaVisiteur />,
  },
  {
    path: "work-visa",
    element: <VisaTravail />,
  },
  {
    path: "study-visa",
    element: <VisaEtude />,
  },
  {
    path: "permanent-residence-quebec-visa",
    element: <ResidencePermanenteQuebec />,
  },
  {
    path: "permanent-residence-hors-quebec-visa",
    element: <ResidencePermanenteHorsQuebec />,
  },
  {
    path: "supports",
    element: <Accueil />,
  },
  {
    path: "settings",
    element: <Box> En cours de developpement !</Box>,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "caq",
    element: <CAQ />,
  },
];

export default MainRoutes;
