// assets
import { UnorderedListOutlined } from "@ant-design/icons";

// icons
const icons = { UnorderedListOutlined };

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const RSFDoc = {
	id: "visa-assist",
	title: "Assistance VISA",
	type: "group",
	children: [
		{
			id: "RSF-Documentation",
			title: "Documentation RSF ",
			type: "item",
			url: "/rsf-documentation",
			icon: icons.UnorderedListOutlined,
		},
	],
};

export default RSFDoc;
