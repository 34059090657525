import { lazy } from "react";
import Loadable from "../components/loader/Loadable";

// Render
const RSF = Loadable(
  lazy(() => import("../pages/RSF-Documentation/RSFDocumentation"))
);

const Payment = Loadable(lazy(() => import("../pages/financials/Payment")));

// Routing
const UserRoutes = [
  {
    path: "rsf-documentation",
    element: <RSF />,
  },
  {
    path: "payment",
    element: <Payment />,
  },
];
export default UserRoutes;
