// assets
import {
  BookOutlined,
  BankOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";

// icons
const icons = {
  BookOutlined,
  BankOutlined,
  AuditOutlined,
  AddCardOutlinedIcon,
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: "group-demand",
  title: "Demandes",
  type: "group",
  children: [
    {
      id: "admissions",
      title: "Admissions",
      type: "item",
      url: "/admissions",
      icon: icons.BookOutlined,
    },
    {
      id: "housings",
      title: "Logements",
      type: "item",
      url: "/housings",
      icon: icons.BankOutlined,
    },
    {
      id: "supports",
      title: "Accueil à l'aeroport",
      type: "item",
      url: "/supports",
      icon: icons.AuditOutlined,
    },
    {
      id: "caq",
      title: "CAQ",
      type: "item",
      url: "/caq",
      icon: icons.AddCardOutlinedIcon,
    },
  ],
};

export default utilities;
